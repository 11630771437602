import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import company from '../../../utils/api/company';
import { addCompanyDetails } from '../../../redux/slices/CompanySlice';
import { addServerDetails } from '../../../redux/slices/ServerSlice';

import TopContent from '../../../Shared/Components/TopContent';
import Button from '../../../Shared/Components/Button';
import { Input } from '../../../Shared/Components/Form/Input';
import ModalBox from '../../../Shared/Components/ModalBox';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import Success from '../../../Shared/Components/Success';

import profile from '../../../assets/images/download.jpg';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import {
  descriptionValidation,
  emailValidation,
  integerValidation,
  nameValidation,
  passwordValidation,
  phoneNumberValidation,
  pincodeValidation,
  textNumberValidation,
} from '../../../Shared/Components/Form/Validation';
import { addDistributorDetails } from '../../../redux/slices/DistributorSlice';
import { acceptedImageTypes, fileUploadValidation } from '../../../utils/helpers';
import AutocompleteBox from '../../../Shared/Components/Form/AutoComplete';

function CompanyAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { account } = useParams();
  const [state, setState] = useState([]);
  const [route, setRoute] = useState({
    route: '',
    text: '',
  });
  const [countryCode, setCountryCode] = useState('');
  const [modalView, setModalView] = useState({});
  const [city, setCity] = useState([]);
  const [countries, setCountries] = useState([]);
  const [disable, setDisable] = useState(true);
  const [image, setImage] = useState('');
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const [value, setValue] = useState({});

  const [buttonStatus, setButtonStatus] = useState('');

  useEffect(() => {
    if (
      value.company_name
      && value.company_email
      && value.address
      && value.city
      && value.state
      && value.country
      && value.pincode
      && value.phone_number
      && value.user_full_name
      && value.user_name
      && value.user_email
      && value.password
      && (account === 'cms' || account === 'cmsServer' || value.margin_percentage)
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);
  useEffect(() => {
    const temp = Country.getAllCountries().map((val) => ({ id: val.isoCode, label: val.name }));
    setCountries(temp);
    if (account === 'cms') {
      setRoute({ route: '../', text: t('company') });
    } else if (account === 'cmsServer') {
      setRoute({ route: '../cmsServer/direct', text: t('cmsServer') });
    } else {
      setRoute({ route: '../distributor', text: t('distributor') });
    }
  }, [navigate]);

  const handleChange = (name, data) => {
    if (data) {
      if (name === 'country') {
        setValue({
          ...value,
          country: data.label,
          state: '',
          city: '',
        });
        const state1 = State.getStatesOfCountry(data.id).map((val) => ({ id: val.isoCode, label: val.name }));
        setState(state1);
        setCountryCode(data.id);
      } else if (name === 'state') {
        const city1 = City.getCitiesOfState(countryCode, data.id)
          .map((val) => ({ id: val.isoCode, label: val.name }));
        setCity(city1);
        setValue({
          ...value,
          state: data.label,
          city: '',
        });
      } else if (name === 'city') {
        setValue({ ...value, city: data.label });
      }
    }
    if (name !== 'country' && name !== 'state' && name !== 'city') {
      setValue({ ...value, [name]: data });
    }
  };

  const onChangePicture = (e) => {
    const maxFileSizeInMB = 1;
    const checkFileValidation = fileUploadValidation(e.target.files[0], maxFileSizeInMB, 'image');
    if (checkFileValidation === '') {
      setValue({ ...value, logo: e.target.files[0] });
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImage(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    } else {
      dispatch(setErrorNotification({ message: checkFileValidation }));
    }
  };

  const addCompany = () => {
    navigate('../');
  };

  const submit = () => {
    console.log(value, 'SUBMIT');
    setButtonStatus('loading');
    setSubmitButtonClicked(true);
    if (
      nameValidation(1, 100, value?.company_name)
      && textNumberValidation(0, 50, value?.tax_id)
      && emailValidation(value?.company_email)
      && descriptionValidation(255, value?.address)
      && pincodeValidation(value?.pincode)
      && phoneNumberValidation(value?.phone_number)
      && nameValidation(1, 50, value?.user_full_name)
      && nameValidation(1, 50, value?.user_name)
      && emailValidation(value?.user_email)
      && passwordValidation(value?.password)
      && (account === 'cms' || account === 'cmsServer' || value.margin_percentage)
      && value.city
      && value.state
      && value.country
    ) {
      let message = '';
      let redirectPath = '';

      // Set message and redirect path based on account type
      switch (account) {
        case 'cms':
          message = t('companyCreatedSuccess');
          redirectPath = '../direct';
          break;
        case 'cmsServer':
          message = t('serverCreatedSuccess');
          redirectPath = '../cmsServer/direct';
          break;
        case 'distributor':
          message = t('distributorCreatedSuccess');
          redirectPath = '../distributor';
          break;
        default:
          redirectPath = '../';
      }
      company.addCompanies({
        ...value,
        account_type: account,
        tax_id: value?.tax_id ? value?.tax_id : null,
      }).then((response) => {
        setButtonStatus('success');
        setModalView({ content: <Success message={message} /> });
        setTimeout(() => {
          setButtonStatus('');
          // Update store based on account type
          if (account === 'cms') {
            dispatch(addCompanyDetails(response.data));
          } else if (account === 'cmsServer') {
            dispatch(addServerDetails(response.data));
          } else {
            console.log(response.data, 'RESPONSE');
            dispatch(addDistributorDetails(response.data));
          }

          // Navigate to the correct path based on account type
          navigate(redirectPath);
        }, 3000);
      }).catch((err) => {
        setButtonStatus('');
        dispatch(setErrorNotification(err?.response?.data));
      });
    } else {
      setButtonStatus('');
    }
  };
  const getAccountLabel = () => {
    if (account === 'cms') {
      return t('cmsAdmin');
    } if (account === 'cmsServer') {
      return t('cmsServer');
    } return t('distributorAdmin');
  };
  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        closeModal={() => setButtonStatus('')}
        modalView={modalView}
        notification
      />
      <TopContent
        label={[route?.text, t('createNewCompany')]}
        click={addCompany}
        labelClickFunc={() => navigate(route?.route)}
        customLabelClickFunc
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area-form-1">
            <div className="company-form">
              <div className="profile-upload">
                <label htmlFor="file" className="upload-click">
                  <img src={image || profile} alt="profile" />
                  {!image ? <p>{t('imageFormat')}</p> : <p><span>{t('changeProfile')}</span></p>}
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={onChangePicture}
                  style={{ display: 'none' }}
                  accept={acceptedImageTypes}
                />
              </div>
              <div className="details-upload">
                <div className="company-info">
                  <h2>{t('companyInformation')}</h2>
                  <div className="form-field">
                    <Input
                      type="text"
                      label={t('companyName')}
                      required
                      name="company_name"
                      value={value?.company_name}
                      change={handleChange}
                      placeholder={t('enterCompanyName')}
                      error={!nameValidation(1, 100, value?.company_name) && submitButtonClicked}
                      errorMessage={t('companyNameValidation')}
                    />
                    <Input
                      type="text"
                      label={t('taxId')}
                      name="tax_id"
                      value={value?.tax_id}
                      change={handleChange}
                      placeholder={t('enterTaxId')}
                      error={!textNumberValidation(0, 50, value?.tax_id) && submitButtonClicked}
                      errorMessage={t('taxIdValidation')}
                    />
                  </div>
                  <div className="form-field">
                    <Input
                      type="number"
                      label={t('phoneNumber')}
                      required
                      name="phone_number"
                      value={value?.phone_number}
                      change={handleChange}
                      placeholder={t('enterPhoneNumber')}
                      error={!phoneNumberValidation(value?.phone_number) && submitButtonClicked}
                      errorMessage={t('invalidPhoneNumber')}
                    />
                    <Input
                      type="email"
                      label={t('mailId')}
                      required
                      name="company_email"
                      value={value?.company_email}
                      change={handleChange}
                      placeholder={t('enterCompanyMailId')}
                      error={!emailValidation(value?.company_email) && submitButtonClicked}
                      errorMessage={t('invalidEmail')}
                    />
                  </div>
                  <Input
                    type="text"
                    label={t('address')}
                    required
                    name="address"
                    value={value?.address}
                    change={handleChange}
                    placeholder={t('enterCompanyAddress')}
                    error={!descriptionValidation(255, value?.address) && submitButtonClicked}
                    errorMessage={t('addressValidation')}
                  />
                  <div className="form-field">
                    <SelectBox
                      type="text"
                      label={t('country')}
                      required
                      name="country"
                      value={value?.country}
                      onchange={handleChange}
                      lists={countries}
                      placeholder={t('selectCountry')}
                      id="country"
                    />
                    <AutocompleteBox
                      type="text"
                      label={t('state')}
                      required
                      name="state"
                      value={value?.state}
                      onchange={handleChange}
                      lists={state}
                      placeholder={t('selectState')}
                      id="state"
                      allowNewOption
                    />
                  </div>
                  <div className="form-field">
                    <AutocompleteBox
                      type="text"
                      label={t('city')}
                      required
                      name="city"
                      value={value?.city}
                      onchange={handleChange}
                      lists={city}
                      placeholder={t('selectCity')}
                      id="city"
                      allowNewOption
                    />
                    <Input
                      type="text"
                      label={t('pinCode')}
                      required
                      name="pincode"
                      value={value?.pincode}
                      change={handleChange}
                      placeholder={t('enterPinCode')}
                      error={!pincodeValidation(value?.pincode) && submitButtonClicked}
                      errorMessage={t('invalidPincode')}
                    />
                  </div>
                </div>
                <div className="user-info">
                  <h2>{t('userInformation')}</h2>
                  <div className="form-field">
                    <Input
                      type="text"
                      label={t('fullName')}
                      required
                      name="user_full_name"
                      value={value?.user_full_name}
                      change={handleChange}
                      placeholder={t('enterFullName')}
                      error={!nameValidation(1, 50, value?.user_full_name) && submitButtonClicked}
                      errorMessage={t('companyUserFullNameValidation')}
                    />
                    <Input
                      type="text"
                      label={t('username')}
                      required
                      name="user_name"
                      value={value.user_name}
                      change={handleChange}
                      placeholder={t('enterUsername')}
                      error={!nameValidation(1, 50, value?.user_name) && submitButtonClicked}
                      errorMessage={t('userNameValidation')}
                    />
                  </div>
                  <Input
                    type="text"
                    label={t('role')}
                    // required
                    name="role"
                    value={getAccountLabel()}
                    readonly
                  />
                  <div className="form-field">
                    <Input
                      type="text"
                      label={t('mailId')}
                      required
                      name="user_email"
                      value={value?.user_email}
                      change={handleChange}
                      placeholder={t('enterUserMailId')}
                      error={!emailValidation(value?.user_email) && submitButtonClicked}
                      errorMessage={t('invalidEmail')}
                    />
                    <Input
                      type="password"
                      label={t('password')}
                      required
                      name="password"
                      value={value?.password}
                      change={handleChange}
                      placeholder={t('enterPassword')}
                      error={!passwordValidation(value?.password) && submitButtonClicked}
                      errorMessage={t('passwordRequirements')}
                    />
                  </div>
                </div>
                {
                  account === 'distributor' && (
                    <div className="user-info">
                      <h2>{t('revenueInformation')}</h2>
                      <div className="form-field">
                        <Input
                          type="text"
                          label={t('distributorMargin%')}
                          required
                          name="margin_percentage"
                          value={value?.margin_percentage}
                          change={handleChange}
                          placeholder={t('enterRevenue%')}
                          error={!integerValidation(0, 100, value?.margin_percentage) && submitButtonClicked}
                          errorMessage={t('inValidPercentage')}
                        />
                      </div>
                    </div>
                  )
                }
                <div className="form-button-group">
                  <div className="form-button">
                    <Button label={t('back')} click={addCompany} classes="default-button" />
                  </div>
                  <div className="form-button">
                    <Button
                      label={buttonStatus === 'loading' ? t('creating') : t('create')}
                      click={submit}
                      classes={buttonStatus === 'loading' ? 'success-button loading-btn' : 'success-button'}
                      disabled={disable}
                      loading={buttonStatus === 'loading'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyAdd;
